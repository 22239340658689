.login-view {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	// &-bg {
	//   width: 60%;

	//   img {
	//     width: 100%;
	//     height: 100%;
	//     object-fit: cover;
	//   }
	// }

	&-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(7px);
		border: 1px solid #e7e7e7;
		padding: 80px;
		border-radius: 15px;
		box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	}

	&-logo {
		&__title {
			color: #4da2d9;
			text-transform: uppercase;
			margin: 0;
			font-weight: 500;
			font-size: 50px;

			img {
				max-width: 400px;
				width: 100%;
				margin-bottom: 50px;
			}
		}

		&__subtitle {
			font-family: Roboto;
			margin: 0 0 50px;
			font-size: 12px;
		}
	}

	&-form {
		display: flex;
		flex-direction: column;

		&__subtitle {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			color: #2d3748;
			margin: 0 0 2px;
		}

		&__title {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 30px;
			line-height: 35px;
			color: #1a202c;
			margin: 0 0 41px;
		}

		&__input {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.login-view {
		padding: 15px;
		&-container {
			width: 100%;
			padding: 40px 20px;
		}
	}
}

@media screen and (max-width: 565px) {
	.login-view {
		min-height: 100vh;
		min-height: -webkit-fill-available;
	}
	html {
		height: -webkit-fill-available;
	}
}
